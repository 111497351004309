// @flow
import React from 'react';
import cx from 'classnames';

import styles from './VideoButton.module.scss';

const VideoButton = ({ className, background, title, onClick }) => (
  <button className={cx(styles.container, className)} onClick={onClick}>
    <img src={background} alt="Video Background" />
    <span className={styles.button}>
      <img
        className={styles.icon}
        src={require('../../../images/campaign-202109/play-circle.png')}
        alt="Play Icon"
      />
      <img
        className={styles.text}
        src={require('../../../images/campaign-202109/play-text.png')}
        alt="Play Text"
      />
    </span>
    {title && <span className={styles.title}>{title}</span>}
  </button>
);

export default VideoButton;
