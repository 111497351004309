// @flow
import React from 'react';

import styles from './VideoPlayer.module.scss';

const VideoPlayer = ({ url, onClose }) => (
  <div className={styles.container}>
    <img
      alt="Paidy Logo"
      src={require('../../../images/logo-white.svg')}
      className={styles.logo}
    />
    <button className={styles.closeBtn} onClick={onClose}>
      <img
        className={styles.cw}
        src={require('../../../images/campaign-202109/icon-bar.svg')}
        alt="Menu Icon"
      />
      <img
        className={styles.ccw}
        src={require('../../../images/campaign-202109/icon-bar.svg')}
        alt="Menu Icon"
      />
    </button>
    <iframe
      className={styles.iframe}
      src={url}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export default VideoPlayer;
