// @flow
import React from 'react';
import cx from 'classnames';
import styles from './Title.module.scss';

const Title = ({ className, index, text }) => {
  return (
    <>
      <div className={styles.sp}>
        <div className={styles.index}>{index}</div>
        <h2 className={cx(styles.title, className)}>{text}</h2>
      </div>

      <div className={styles.pc}>
        <h2 className={cx(styles.title, className)}>
          <div className={styles.index}>{index}</div>
          {text}
        </h2>
      </div>
    </>
  );
};

export default Title;
