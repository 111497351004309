import React from 'react';

export const MENU_ITEMS = [
  {
    id: 'top',
    title: 'TOP',
  },
  {
    id: 'tvcm',
    title: 'TVCM',
    subtitle: 'CAST',
  },
  {
    id: 'interview',
    title: 'INTERVIEW',
  },
  {
    id: 'paidy-intro',
    title: (
      <>
        {`WHAT'S Paidy`}
        <span>?</span>
      </>
    ),
  },
];

export const CAMPAIGN_202109_QAS = [
  {
    question: '─ デビュー当時を振り返って',
    answer:
      '６年間続いていた４人暮らしの間でデビューが決まって、4人とも違う会社に勤めていたので、「会社にやめることを言わないといけないね」という話で持ち切りで、嬉しかったです。20代のほとんどは四人暮らしに費やしましたね。',
  },
  {
    question:
      '─ 夢が叶った時に自分に、または周りの人に買った思い出深いアイテム',
    answer:
      'メンバーで「レザージャケットとか買った方がいいのでは？」という話になって、古着屋さんに買いに行きました。それまでレザージャケットって高いイメージだったし、俺たちが着て似合うものなのか？と思ってたんですけど、試着してみたら、思いのほか心地よくて。それで、とあるミュージックビデオで着て撮影しました。最近はあまり着てないけど、今着てもかっこいいと思います（笑）。',
  },
  {
    question: '─ CMに出演した感想',
    answer:
      '自分の家なんじゃないかってくらい環境を作っていただいて、リラックスできました。本当にスタッフの皆さんに感謝です。ちゃんと自分の言葉で話せたし、猫も連れて来られたし、本当に普段通りの自分で動くことができたので、ありがたいと思っています。',
  },
  {
    question: '─ 飼い猫のラテちゃんとのCM初共演の感想',
    answer:
      '大人しくしてくれていい子でした。家だともっと暴れるので、家でもこのくらいおとなしいといいなと思いました（笑）もう一匹猫を飼っているので、次回はその子と共演したいですね。',
  },
  {
    question: '─ 夢を追うすべての人へメッセージ',
    answer:
      '僕の中では「夢を夢で終わらせたくない」と思っていて。夢ってすごくキラキラしたものだけど、夢を目指すと色々危険な部分もあったりして、色んなものを犠牲にしないといけなかったり、現実を見せられて絶望したり、それでも果敢に挑んでいくっていう、勇気と度胸を必要とする行為だと思っています。だから、自分の中では、幼少期の頃はキラキラしたイメージからギラギラしたものに変わっていきました。「絶対に掴んでやるぞ」というような。例えばデビューするにしても、ただ待っているだけでは無理だし、じゃあ何が必要なのか？って言ったら、やっぱり、デモテープ作るにしても、いい曲を沢山作らなきゃいけないし、いい楽器を買わないといけないし、いいレコーディングスタジオでいいエンジニアさん達と作らないといけないし。なので、結構現実的な要素がどんどん必要なんだなって気づいてくると思う。そこで、諦めないかどうかが大事になってくると思います。',
  },
  {
    question: '─ これから叶えたい夢',
    answer:
      'デビューは決まったけど、そこからもっともっとやれてない会場だったり、色んな国でやりたいなと思っていて。そこはまだ自分の中では達成できてないので、まだ夢が全部叶ったとは思っていないです。まだまだ、これからだなって思います。',
  },
];

export const CAMPAIGN_202109_APP_GUIDES = [
  {
    icon: require('../../../images/campaign-202109/guide-1.svg'),
    title: (
      <span>
        メアドと携帯番号だけでお買い物。
        <br />
        お支払いは翌月でOK。
      </span>
    ),
    description:
      'ペイディなら、メアドと携帯番号だけでオンラインショッピングを手軽に楽しめます。毎月のご請求のお支払いは、まとめて翌月10日まででOKです。',
  },
  {
    icon: require('../../../images/campaign-202109/guide-2.svg'),
    title: (
      <span>
        分割手数料無料*の
        <br />
        3回あと払い。
      </span>
    ),
    description: (
      <>
        お買い物のお支払いを、分割手数料無料*で3回に分けることができます。より賢く計画的に、欲しい商品を購入することが可能です。
        <small>*口座振替・銀行振込のみ無料</small>
      </>
    ),
  },
  {
    icon: require('../../../images/campaign-202109/guide-3.svg'),
    title: (
      <span>
        使えるお店が広がる、
        <br />
        どこでもペイディ。
      </span>
    ),
    description:
      'Visaのオンライン加盟店なら、ペイディを利用してどこでもお買い物を楽しめます。3回あと払いも利用可能です。',
  },
];

export const TWITTER_SHARE_TITLE = 'あと払いペイディ｜TVCM｜翌月後払いのPaidy';
