/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { TwitterShareButton } from 'react-share';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Header.module.scss';
import { MENU_ITEMS, TWITTER_SHARE_TITLE } from './constants';
import Title from './Title';
import TwitterIcon from '../../../images/campaign-202109/twitter-icon.svg';

const Header = ({ location }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [isWhiteLogo, setIsWhiteLogo] = useState(false);

  useEffect(() => {
    const updateBgOnScroll = () => {
      setShowLogo(window.scrollY > 0);

      setIsWhiteLogo(window.scrollY < 0.9 * window.innerHeight);
    };

    window.addEventListener('scroll', updateBgOnScroll);

    return () => window.removeEventListener('scroll', updateBgOnScroll);
  }, []);

  return (
    <>
      <div
        className={cx(styles.header, styles.sp, {
          [styles.show]: showLogo && !isOpened,
          [styles.menuOpen]: isOpened,
        })}
      >
        <Link className={styles.logo} to="/">
          <img
            alt="Paidy Logo"
            src={require('../../../images/logo-color.svg')}
          />
        </Link>
        <button
          className={styles.button}
          onClick={() => setIsOpened(!isOpened)}
        >
          <img
            className={cx({ [styles.cw]: isOpened })}
            src={require('../../../images/campaign-202109/icon-bar.svg')}
            alt="Menu Icon"
          />
          <img
            className={cx({ [styles.ccw]: isOpened })}
            src={require('../../../images/campaign-202109/icon-bar.svg')}
            alt="Menu Icon"
          />
        </button>
      </div>
      <div className={styles.pc}>
        <Link
          className={cx({
            [styles.logo]: true,
            [styles.show]: showLogo || isOpened,
            [styles.white]: isWhiteLogo || isOpened,
          })}
          to="/"
        >
          <img
            alt="Paidy Logo"
            src={require('../../../images/logo-color.svg')}
          />
        </Link>
        <button
          className={styles.button}
          onClick={() => setIsOpened(!isOpened)}
        >
          <img
            className={cx({ [styles.cw]: isOpened })}
            src={require('../../../images/campaign-202109/icon-bar.svg')}
            alt="Menu Icon"
          />
          <img
            className={cx({ [styles.ccw]: isOpened })}
            src={require('../../../images/campaign-202109/icon-bar.svg')}
            alt="Menu Icon"
          />
        </button>
      </div>
      <div className={cx(styles.appDownload, { [styles.show]: showLogo })}>
        <OutboundLink
          href="https://apps.apple.com/jp/app/paidy/id1220373112"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={require('../../../images/campaign-202109/app-store.png')}
            alt="App Store"
          />
        </OutboundLink>
        <OutboundLink
          href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={require('../../../images/campaign-202109/play-store.png')}
            alt="Play Store"
          />
        </OutboundLink>
      </div>
      <nav className={cx(styles.menu, { [styles.show]: isOpened })}>
        <ul>
          {MENU_ITEMS.map((item, i) => (
            <li key={`menu-item-${i}`}>
              <Link
                to={`${location.pathname}#${item.id}`}
                onClick={() => setIsOpened(false)}
              >
                <Title
                  className={styles.title}
                  index={`0${i}`.slice(-2) + '.'}
                  text={item.title}
                />
                {item.subtitle && (
                  <span className={styles.subtitle}>{item.subtitle}</span>
                )}
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.social}>
          <span className={styles.subLi}>
            <Link to="/">Paidy brand Site</Link>
          </span>
          <TwitterShareButton
            url={location.href}
            title={TWITTER_SHARE_TITLE}
            className={styles.twitterBtn}
          >
            SHARE
            <img src={TwitterIcon} alt="Twitter Icon" />
          </TwitterShareButton>
        </div>
      </nav>
    </>
  );
};

export default Header;
