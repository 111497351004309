// @flow
/* eslint-disable react/no-danger */

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { TwitterShareButton } from 'react-share';
import { connect } from 'react-redux';

import '../components/Layout/Layout.scss';
import styles from '../styles/pages/campaign_202109.module.scss';
import { fetchCampaign202109 } from '../redux/campaign-202109';
import gaTrackInternalLink from '../utils/ga-tracking';
import Footer from '../components/Footer/Footer';
import FooterMobile from '../components/Footer/FooterMobile';
import Title from '../components/Special/Campaign202109/Title';
import Header from '../components/Special/Campaign202109/Header';
import VideoButton from '../components/Special/Campaign202109/VideoButton';
import {
  CAMPAIGN_202109_APP_GUIDES,
  CAMPAIGN_202109_QAS,
  TWITTER_SHARE_TITLE,
} from '../components/Special/Campaign202109/constants';

import ogImage from '../images/campaign-202109/og-image.png';
import CM15Bg from '../images/campaign-202109/cm-15.jpg';
import CM30Bg from '../images/campaign-202109/cm-30.jpg';
import CM60Bg from '../images/campaign-202109/cm-60.jpg';
import CMMakingBg from '../images/campaign-202109/cm-making.jpg';
import TwitterIcon from '../images/campaign-202109/twitter-icon.svg';
import VideoPlayer from '../components/Special/Campaign202109/VideoPlayer';
import { getJPDateString } from '../utils';
import { SITE_METADATA } from '../constants';

const Campaign202109 = ({ location, data, _fetchCampaign202109 }) => {
  const [videoUrl, setVideoUrl] = useState(null);

  const now = new Date().getTime();
  const startTime = new Date(data.startDate).getTime();
  const endTime = new Date(data.endDate).getTime() + 60000;
  const ctaActive = now >= startTime && now <= endTime;

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const siteUrl = site.siteMetadata.siteUrl;

  useEffect(() => {
    _fetchCampaign202109();
    scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <Helmet
        title={SITE_METADATA.campaign202109.title}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: `description`,
            content: SITE_METADATA.campaign202109.description,
          },
          {
            property: `og:title`,
            content: SITE_METADATA.campaign202109.title,
          },
          {
            property: `og:description`,
            content: SITE_METADATA.campaign202109.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${siteUrl}${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: SITE_METADATA.campaign202109.title,
          },
          {
            name: `twitter:description`,
            content: SITE_METADATA.campaign202109.description,
          },
          {
            property: 'og:url',
            content: `${siteUrl}`,
          },
          {
            property: 'og:image',
            content: `${siteUrl}${ogImage}`,
          },
          {
            property: 'og:image:secure',
            content: `${siteUrl}${ogImage}`,
          },
          {
            property: 'og:image:type',
            content: `image/png`,
          },
          {
            property: 'og:image:width',
            content: `1200`,
          },
          {
            property: 'og:image:height',
            content: `630`,
          },
        ]}
        script={[
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            (function(d){
              var l = d.createElement("link");
              var s = d.getElementsByTagName("script")[0];
              l.rel = "stylesheet";
              l.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Noto+Sans+JP:wght@400;900&display=swap&subset=japanese";
              s.parentNode.insertBefore(l, s);
            })(document);
            `,
          },
        ]}
      />
      <Header location={location} />
      {videoUrl && (
        <VideoPlayer url={videoUrl} onClose={() => setVideoUrl(null)} />
      )}
      <section id="top" className={styles.top}>
        <div className={styles.smartphone}>
          <img alt="Paidy Logo" src={require('../images/logo-color.svg')} />
          <span>その買い物を、ペイディと。</span>
        </div>
        <h1>
          <span>買えるは、</span>
          <br />
          <span>叶えるの始まり。</span>
        </h1>
        <img
          className={styles.scroll}
          alt="Scroll Icon"
          src={require('../images/campaign-202109/scroll.svg')}
        />
        <div className={styles.appDownload}>
          <img
            alt="QR Code"
            src={require('../images/campaign-202109/qr-code.png')}
            className={styles.pc}
          />
          <OutboundLink
            href="https://apps.apple.com/jp/app/paidy/id1220373112"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={require('../images/campaign-202109/app-store.png')}
              alt="App Store"
            />
          </OutboundLink>
          <OutboundLink
            href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={require('../images/campaign-202109/play-store.png')}
              alt="Play Store"
            />
          </OutboundLink>
        </div>
      </section>
      <section className={styles.statement}>
        <p>
          夢に、値段はつけられない。
          <br />
          でも、そのきっかけを買うことはできる。
          <br />
          ほしい物を買うことは、ほしい未来に踏み出すこと。
          <br />
          さあ、あなたはペイディで何を買う？
        </p>
        <h3>買えるは、叶えるの始まり。</h3>
      </section>
      <section id="tvcm" className={styles.tvcm}>
        <Title index="01." text="TVCM" />
        <div className={styles.videoWrapper}>
          <VideoButton
            background={CM15Bg}
            title="Short ver. (15sec)"
            onClick={() => setVideoUrl(data.cmShortUrl)}
          />
          <VideoButton
            background={CM30Bg}
            title="Long ver. (30sec)"
            onClick={() => setVideoUrl(data.cmLongUrl)}
          />
          <VideoButton
            background={CM60Bg}
            title="Full ver. (60sec)"
            onClick={() => setVideoUrl(data.cmFullUrl)}
          />
        </div>
        <div className={styles.profileWrapper}>
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <span className={styles.text}>Cast</span>
            </div>
            <div className={styles.info}>
              <p>
                <span>
                  <span className={cx(styles.bold, styles.big)}>川上 洋平</span>
                  / Yoohei Kawakami
                </span>
                <br />
                <span>
                  <span className={styles.bold}>ミュージシャン</span>/ Musician
                </span>
              </p>
              <p>
                4人組ロックバンド[Alexandros]でボーカル＆ギターを担当し、ほぼ全曲の作詞・作曲を手掛ける。TVドラマやCM、映画など多岐に亘り楽曲提供を行い幅広い層に支持されている。「ウチの娘は、彼氏が出来ない!!」(日本テレビ系)にドラマ初出演、またラジオパーソナリティー、映画連載コラム寄稿など多彩に活動中。
              </p>
            </div>
          </div>
          <div className={styles.makingVideo}>
            <VideoButton
              className={styles.videoButton}
              background={CMMakingBg}
              onClick={() => setVideoUrl(data.cmMakingUrl)}
            />
            <span className={styles.text}>The Making of</span>
            <span className={styles.text}>Movie</span>
          </div>
        </div>
      </section>
      <section id="interview" className={styles.interview}>
        <Title index="02." text="INTERVIEW" />
        {CAMPAIGN_202109_QAS.map((qa, i) => (
          <div className={styles.qa} key={`qa-${i}`}>
            <span>{qa.question}</span>
            <p>{qa.answer}</p>
          </div>
        ))}
      </section>
      {ctaActive && (
        <section className={styles.cta}>
          <h3>{data.ctaTitle}</h3>
          <p dangerouslySetInnerHTML={{ __html: data.ctaContent }} />
          <p className={styles.bold}>
            キャンペーン期間：
            <br className={styles.sp} />
            {getJPDateString(data.startDate)}～{getJPDateString(data.endDate)}
          </p>
          <OutboundLink
            className={cx('btn charcoal', [styles.ctaBtn], {
              [styles.disabled]: !data.tweetUrl,
            })}
            href={data.tweetUrl}
            rel="noopener noreferrer"
            target="_blank"
            disabled={!data.tweetUrl}
          >
            キャンペーンを今すぐチェック
          </OutboundLink>
        </section>
      )}
      <section id="paidy-intro" className={styles.paidyIntro}>
        <Title
          index="03."
          text={
            <>
              {`WHAT'S Paidy`}
              <span className={styles.naughtyMark}>?</span>
            </>
          }
        />
        <h3>スマホでかしこくあと払い。</h3>
        <div className={styles.guides}>
          {CAMPAIGN_202109_APP_GUIDES.map((guide, i) => (
            <div key={`guide-${i}`} className={styles.guide}>
              <img src={guide.icon} alt="Guide Icon" />
              <p>{guide.title}</p>
              <p>{guide.description}</p>
            </div>
          ))}
        </div>
        <Link
          className={cx('btn charcoal', styles.ctaBtn)}
          to="/"
          onClick={gaTrackInternalLink}
        >
          ペイディについて詳しく知る
        </Link>
      </section>
      <section className={styles.paidyOutro}>
        <div className={styles.appDownload}>
          <div className={styles.pc}>
            <div className={styles.qr}>
              <img
                alt="QR Code"
                src={require('../images/campaign-202109/qr-code.png')}
              />
            </div>
          </div>

          <h3>アプリのダウンロードはこちら</h3>
          <OutboundLink
            href="https://apps.apple.com/jp/app/paidy/id1220373112"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={require('../images/campaign-202109/app-store.png')}
              alt="App Store"
            />
          </OutboundLink>
          <OutboundLink
            href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={require('../images/campaign-202109/play-store.png')}
              alt="Play Store"
            />
          </OutboundLink>
        </div>
        <TwitterShareButton
          url={location.href}
          title={TWITTER_SHARE_TITLE}
          className={styles.twitterBtn}
        >
          <span />
          <span>SHARE</span>
          <img src={TwitterIcon} alt="Twitter Icon" />
        </TwitterShareButton>
      </section>
      <div className={styles.sp}>
        <FooterMobile />
      </div>
      <div className={styles.pc}>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.campaign202109.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  _fetchCampaign202109: () => dispatch(fetchCampaign202109()),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Campaign202109);
